import React,{useState} from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import "@fontsource/roboto";
import "@fontsource/nunito-sans";
import "@fontsource/dm-sans";
import styles from './footer.module.css';
import { Modal } from 'react-bootstrap';
import { Link,useNavigate } from 'react-router-dom';


const Frountfooter = () => {

    let navigate = useNavigate;
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  function buyersignup(){
    navigate('/buyersignup')
}

function sellerSignup(){
   navigate('/sellersignup') 
}


    return ( <>
       <div className={`container-fluid mt-5 `+styles.footer}>
            <div className="row">
                <div className={`col-md-6 `+styles.footerborder}>
                    <h1><img src='./images/logo.png' /></h1>
                    <p>Ready2Resale All rights reserved Dubai.</p>
                </div>

                <div className="col-md-6">
                <div className={styles.dashboardmenu}>
                 <ul>
                  <li> <Link to="/how">How it works</Link> </li>
                  <li> <Link to="/about">About</Link> </li>
                  <li> <Link to="/faqs"> FAQ </Link> </li>
                  <li> <Link to="/contactus">Contact</Link> </li>
                  <li><Link  to="/login">Login</Link></li>
                  <li> <button className={`btn `+styles.signup} onClick={handleShow}>Signup</button> </li>     
                </ul>
                <div>
                
                </div>
                
                </div>
                <div className={styles.copyright}>
                 <p className={`mt-5 `} >&copy; 2022  <span>Privacy — Terms</span>   </p>
                 <p></p>
                 </div>
                
                </div>
                <Modal size="lg" show={show} onHide={handleClose} className={styles.modelcont}>
        <Modal.Header className={styles.logoutmodel} closeButton>
          <Modal.Title>Signup</Modal.Title>
        </Modal.Header>
        <Modal.Body>
        <div className='row'>
          <div className='col-md-12'>
          <div className='row'>
              <div className={'col-md-6 text-center '+styles.border}>
                  <img src='./images/bank.svg' />
                  <h1 className={styles.buyertext}>I'm a Buyer</h1>
                  <p className={styles.textgrey}>I want to purchase electronics in bulk.</p>
                  <button className={'btn mb-5 '+styles.signsellbtn} onClick={buyersignup}>
              <div className="my-auto">Buyer Application</div>
              <p className={'mt-4  '+styles.textgrey}>Learn More <i className="fa fa-angle-double-right" aria-hidden="true"></i></p>
              </button>
              </div>
              <div className='col-md-6 text-center'>
                   <img src='./images/phones.svg' />
                  <h1 className={styles.buyertext}>I'm a Seller</h1>
                  <p className={styles.textgrey}>I have electronics to sell.</p>
                  <button className={'btn mb-5 '+styles.signsellbtn} onClick={sellerSignup}>
              <div className="my-auto">Seller Signup*</div>
              <p className={'mt-4  '+styles.textgrey}>Learn More <i className="fa fa-angle-double-right" aria-hidden="true"></i></p>
              </button>
              

              </div>
              <div className='mt-3 text-center'>
              <p className={styles.textgrey}>* Currently only accepting sellers located in the United States</p>
              </div>
          </div>
          </div>
        </div>
        

        </Modal.Body>
        
      </Modal>
            </div>
        </div>   
    </> );
}
 
export default Frountfooter;