import React from 'react';
import './App.css';
import {
  BrowserRouter ,
  Routes,
  Route,
} from "react-router-dom";
import 'bootstrap/dist/css/bootstrap.min.css';
import BuyerRegistration from './features/admin/buyer/registration/Buyerregistration';
import SellerRegistration from './features/admin/seller/registration/Sellerregistrration';
import Buyerdashboard from './features/admin/buyer/dashboard/Buyerdashboard';
import Sellerdashboard from './features/admin/seller/dashboard/Sellerdashboard';
import Sellersignup from './features/admin/seller/signup/Signup';
import Buyersignup from './features/admin/buyer/signup/Signup';
import Buyerlogin from './features/admin/buyer/login/Login';
import Sellerlogin from './features/admin/seller/login/Login';
import Home from './features/frountend/maincontent/main';
import Login from './features/admin/login/login';
import Contactus from './features/frountend/contact-us/contact';
import FAQs from './features/frountend/faqs/faqs';
import Howirworks from './features/frountend/howitswork/howitswork';
import Products from './features/frountend/products/product';
import About from './features/frountend/about-us/about';
import Gradingscale from './features/admin/buyer/gradingscale/Gradingscale';
function App() {
  return (
    <BrowserRouter>
    <Routes>
          <Route path="/"  element={<Home />}></Route>
          <Route path="login"  element={<Login />}></Route>
          <Route path="buyerlogin"  element={<Buyerlogin />}></Route>
          <Route path="sellerlogin"  element={<Sellerlogin />}></Route>
          <Route path="buyersignup"  element={<Buyersignup />}></Route>
          <Route path="sellersignup"  element={<Sellersignup />}></Route>
          <Route path="buyerdashboard" element={<Buyerdashboard />}></Route>
          <Route path="sellerdashboard" element={<Sellerdashboard />}></Route>
          <Route path="buyerregistration"  element={<BuyerRegistration />}></Route>
          <Route path="sellerregistration"  element={<SellerRegistration />}></Route>
          <Route path="contactus"  element={<Contactus />}></Route>
          <Route path="faqs"  element={<FAQs />}></Route>
          <Route path="how"  element={<Howirworks />}></Route>
          <Route path="products"  element={<Products />}></Route>
          <Route path="about"  element={<About />}></Route>
          <Route path="grading"  element={<Gradingscale />}></Route>

        </Routes>
        </BrowserRouter>
  );
}

export default App;
