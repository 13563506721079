import React from 'react';
import Frountfooter from '../footer/footer';
import Frountheader from '../header/header';
import styles from './how.module.css'
const Howirworks = () => {
    return ( <>
    <Frountheader />
    <div className={'container-fluid '+styles.topsection}>
        <div className='row'>
            <h1 className={styles.topheading}>How Assembly Works</h1>
            <p className={styles.topsectiontext}>Assembly simplifies selling used computers, phones, tablets and wearables in bulk, quickly connecting sellers with qualified buyers to maximize the value to both.</p>
        </div>
     </div> 

     <div className='container-fluid'>
        <div className='row'>
            <div className='col-md-2'>
                
            </div>
            <div className='col-md-8'>
            <div className={styles.shadow2xl}>
            <iframe src="https://player.vimeo.com/video/597341260?h=f00d813da8&amp;byline=0&amp;portrait=0" frameBorder="0" allow="autoplay; fullscreen; picture-in-picture"  allowFullScreen="" style={{width:'100%',height:'100%', boxShadow:'0 25px 50px -12px rgba(0,0,0,0.25)'}} ></iframe>
            </div>
            </div>
            <div className='col-md-2'>
            
            </div>
        </div>
    </div> 

    <div className='container'>
     <div className='row p-5 mt-5'>
        <div className='col-md-12 shadow'>
            <p className={styles.signupheading}>1. Sign Up</p>
            <p className={styles.signtext}>There is no cost for you to sign up, create listings, orsell your devices on Assembly.</p>
            <ol>
                <li>Alternatives to username</li>
                <li>Show passwords requirements and strength upfront</li>
                <li>Confirm password?</li>
                <li> Necessary fields</li>
                <li>Validation and error messaging</li>
                <li>Advanced automatic address look-up</li>
                <li>Show progress on longer forms</li>
                <li>Confirmation of registration.</li>
            </ol>
        </div>
     </div>
    </div>


    <div className='container'>
        <div className='row mt-5'>
            <div className='col-md-2'></div>
            <div className={'col-md-8 text-center p-4 '+styles.sellbox}>
            <img src='./images/greenStars.svg' />
            <p className={styles.custmrowh}>Connect with Buyers Today</p>
            <p className={styles.custmrowdesc}>You set the terms. You define what qualities you need in a buyer. You are in control. Our simple, guided listing process collects the information buyers need - and only the information needed - in a streamlined listing.</p> 
            <div className='p-5'>
            <button className={'btn '+styles.sellbtn}>Connect with Buyers Today <i className="fa fa-paper-plane-o" aria-hidden="true"></i></button>
            </div>  
            </div>
            <div className='col-md-2'></div>
        </div>
        </div>
    <Frountfooter />
    </> );
}
 
export default Howirworks;