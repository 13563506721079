import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import "@fontsource/roboto";
import "@fontsource/nunito-sans";
import "@fontsource/dm-sans";
import styles from './header.module.css'
import { Dropdown } from 'react-bootstrap';
import { postData } from '../../services/services';
import { useNavigate } from 'react-router-dom';
const Header = () => {
let naviagate = useNavigate();
    function  logout(){

        var  url ='buyer/logout'
            var response =  postData(url);
            response.then(response=>{
                
                if (response.status == '200') {
                    localStorage.removeItem('access_token');
                    naviagate('/buyerlogin')
                }
          
            })
       }
    return ( <>
    <div className={`container-fluid `+styles.topbar}>
    <div className="row" >
            <div className='col-md-5'><p className={styles.toptext}>Welcome to  Your Dream Online Store!</p></div>
            <div className='col-md-7'>
                
                <div className={styles.dashboardmenu}>
                 <ul>
                  <li>Shop All Auctions</li>
                  <li>For Buyers</li>
                  <li>For Sellers</li>
                  <li>Support</li>     
                </ul> 
                </div>
            </div>
    </div>
    </div>

    <div className="container-fluid !direction !spacing">
        <div className="row">
        <div className='col-md-5'><p className={styles.dashboardnavtext}><img src='./images/logo.png' /></p></div>
            <div className='col-md-6'>
            <div className="d-flex flex-row justify-content-end">
            <div className={styles.search}>
                 <ul>
                  <li> <select className={styles.topselect}>
                      <option>All Categories</option>
                      <option>All Categories</option>
                      <option>Iphone 11</option>
                      <option>Iphone 12</option>
                      <option>Iphonr 13max</option>
                      <option>Samsung</option>
                      </select> </li>
                  <li> <input className={styles.inputsearch} placeholder="search here" /><i className={`fa fa-search `+styles.searchicon} aria-hidden="true"></i>  </li>
                      
                </ul> 
                </div>
     
        </div>
            </div> 
            <div className='col-md-1'>
            <Dropdown>
             
                        
             <Dropdown.Toggle   id="dropdown-split-basic" className={`  `+styles.profile} />

            <Dropdown.Menu>
                <Dropdown.Item  onClick={logout} >Logout</Dropdown.Item>
                
            </Dropdown.Menu>
            
             
             </Dropdown>
            </div>
        </div>
    </div>
    </> );
}
 
export default Header;<>
</>