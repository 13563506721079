import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import "@fontsource/roboto";
import "@fontsource/nunito-sans";
import "@fontsource/dm-sans";
import styles from './sellerdashboard.module.css';
import Footer from '../../footer/Footer';
import { Dropdown,Menu } from 'react-bootstrap';
import { postData } from '../../services/services';
import { useNavigate } from 'react-router-dom';
const Sellerdashboard = () => {
    let naviagate = useNavigate();
    function  logout(){

        var  url ='seller/logout'
            var response =  postData(url);
            response.then(response=>{
                if (response.status == '200') {
                    localStorage.removeItem('access_token');
                    naviagate('/sellerlogin')
                }
           
            })
       }
    return ( <>
    <div className={`container-fluid `+styles.topbar}>
    <div className="row" >
            <div className='col-md-5'><p className={styles.toptext}>Welcome to Ecomart in Your Dream Online Store!</p></div>
            <div className='col-md-7'>
                <div className={styles.dashboardmenu}>
                 <ul>
                  <li>Shop All Auctions</li>
                  <li>For Buyers</li>
                  <li>For Sellers</li>
                  <li>Support</li>     
                </ul> 
                </div>
            </div>
    </div>
    </div>

    <div className="container-fluid !direction !spacing">
        <div className="row">
        <div className='col-md-5'><p className={styles.dashboardnavtext}><img src='./images/logo.png' /></p></div>
            <div className='col-md-6'>
            <div className="d-flex flex-row justify-content-end">
            <div className={styles.search}>
                 <ul>
                  <li> <select className={styles.topselect}>
                      <option>All Categories</option>
                      <option>Iphone 11</option>
                      <option>Iphone 12</option>
                      <option>Iphonr 13max</option>
                      <option>Samsung</option>
                      </select> </li>
                  <li> <input className={styles.inputsearch} placeholder="search here" /><i className={`fa fa-search `+styles.searchicon} aria-hidden="true"></i>  </li>
                      
                </ul> 
                </div>
     
        </div>
            </div> 
            <div className='col-md-1' id="dropdown-basic">
            <Dropdown>
             
                        
             <Dropdown.Toggle   id="dropdown-split-basic" className={`  `+styles.profile} />

            <Dropdown.Menu>
                <Dropdown.Item onClick={logout}>Logout</Dropdown.Item>
                
            </Dropdown.Menu>
            
             
             </Dropdown>
            
            </div>
        </div>
    </div>

    <div className="container mt-5">
        <div className="row ">
            <div className='col-md-4 text-center'>
                <div className={styles.rightprofile}></div>
                <div className={styles.buyername}> <h1>Charles Robbie</h1> </div>
                 <p className={styles.buyerlocation}>New York, USA</p>
                <div className={styles.bitcounter}> 
                    <ul>
                        <li>Total Bids</li>
                        <li>Bids Winning</li>
                        <li>Bid Loosing</li>
                    </ul>
                </div>
                <div className={styles.bitcounrtrnum}> 
                    <ul>
                        <li>120</li>
                        <li>30</li>
                        <li>90</li>
                    </ul>
                </div>
            </div>
            <div className='col-md-4 text-center p-4 mt-2'>
                <div className={styles.card}>
                    <h2>Pending Bids</h2>
                    <h1>23 Bids</h1>
                    <p>Total pending Bids</p>
                    <p className={styles.bitprice}>$659</p>
                    <div className='p-3'>
                    <button className={`btn `+styles.dashbutton}>See All Pending Bids</button>
                    </div>
                    
                </div>
            </div>
            <div className='col-md-4 text-center p-4 mt-2'>
                <div className={styles.card}>
                    <h2>Pending Bids</h2>
                    <h1>23 Bids</h1>
                    <p>Total pending Bids</p>
                    <p className={styles.bitprice}>$659</p>
                    <div className='p-3'>
                    <button className={`btn `+styles.dashbutton}>See All Pending Bids</button>
                    </div>
                    
                </div>
            </div>

        </div>
    </div>

    <div className='container'>
        <div className='row'>
            
            <div className='col-md-4'>
            <div className={styles.sidebar}>
                <ul>
                <li > <img src='./images/Vector (1).png' /> Dashboard</li>
                    <li > <img src='./images/Vector (2).png' /> Profile</li>
                    
                    <li><img src='./images/Vector (4).png' /> Security</li>
                    
                    <li><img src='./images/Vector (7).png' /> Payouts</li>
                    <li><img src='./images/Vector (8).png' /> Settings</li>
                </ul>
                <div className={styles.updratebtn}>
                    <button className='btn'>Upgrade to PRO to get access to all features!</button>
                </div>
            </div>
            </div>

            <div className={`col-md-8 `+styles.dashboardlist}>
                 <div className="row">
                     <div className='col-md-2'>
                     <h1>Orders</h1>
                     </div>
                     <div className='col-md-3'>
                      <div className={styles.ordersearch}>
                          <input className={styles.ordersearchinput} /> <i className={`fa fa-search `+styles.searchicon} aria-hidden="true"></i>
                      </div>
                     </div>
                 </div>
                 <div className='row'>
                     <div className={styles.restable}>
                    <table className="table table-striped">
                    <thead>
                        <tr>
                        <th scope="col">Date Updated</th>
                        <th scope="col">Order #</th>
                        <th scope="col">Warehouse</th>
                        <th scope="col">Type</th>
                        <th scope='col'>Status</th>
                        <th scope='col'>Quantity</th>
                        <th scope='col'>Total</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                        <th scope="row">1</th>
                        <td>Mark</td>
                        <td>Otto</td>
                        <td>@mdo</td>
                        <td>@fat</td>
                        <td>@fat</td>
                        <td>@fat</td>
                        </tr>
                        <tr>
                        <th scope="row">2</th>
                        <td>Jacob</td>
                        <td>Thornton</td>
                        <td>@fat</td>
                        <td>@fat</td>
                        <td>@fat</td>
                        <td>@fat</td>
                        </tr>
                        <tr>
                        <th scope="row">3</th>
                        <td colSpan="2">Larry the Bird</td>
                        <td>@twitter</td>
                        <td>@fat</td>
                        <td>@fat</td>
                        <td>@fat</td>
                        </tr>
                        <tr>
                        <th scope="row">4</th>
                        <td colSpan="2">Larry the Bird</td>
                        <td>@twitter</td>
                        <td>@fat</td>
                        <td>@fat</td>
                        <td>@fat</td>
                        </tr>
                        <tr>
                        <th scope="row">5</th>
                        <td colSpan="2">Larry the Bird</td>
                        <td>@twitter</td>
                        <td>@fat</td>
                        <td>@fat</td>
                        <td>@fat</td>
                        </tr>
                        <tr>
                        <th scope="row">6</th>
                        <td colSpan="2">Larry the Bird</td>
                        <td>@twitter</td>
                        <td>@fat</td>
                        <td>@fat</td>
                        <td>@fat</td>
                        </tr>
                        <tr>
                        <th scope="row">7</th>
                        <td colSpan="2">Larry the Bird</td>
                        <td>@twitter</td>
                        <td>@fat</td>
                        <td>@fat</td>
                        <td>@fat</td>
                        </tr>
                        <tr>
                        <th scope="row">8</th>
                        <td colSpan="2">Larry the Bird</td>
                        <td>@twitter</td>
                        <td>@fat</td>
                        <td>@fat</td>
                        <td>@fat</td>
                        </tr>
                        <tr>
                        <th scope="row">9</th>
                        <td colSpan="2">Larry the Bird</td>
                        <td>@twitter</td>
                        <td>@fat</td>
                        <td>@fat</td>
                        <td>@fat</td>
                        </tr>
                        <tr>
                        <th scope="row">10</th>
                        <td colSpan="2">Larry the Bird</td>
                        <td>@twitter</td>
                        <td>@fat</td>
                        <td>@fat</td>
                        <td>@fat</td>
                        </tr>
                    </tbody>
                    </table>
                  
                    </div>
                 </div>
                 
            </div>
        </div>
    </div>
    <Footer />
    </> );
}
 
export default Sellerdashboard;