import React from 'react'
import styles from './about.module.css'
import "@fontsource/roboto";
import "@fontsource/nunito-sans";
import "@fontsource/dm-sans";
import Frountheader from '../header/header';
import Frountfooter from '../footer/footer';
const About = () => {
    return ( <>
    <Frountheader />
    <div className={'container-fluid '+styles.topsection}>
        <div className='row'>
            <p className={styles.topsectionheading}>About Us</p>
            <p className={styles.topsectiontext}>Assembly simplifies selling used computers, phones, tablets and wearables in bulk, quickly connecting sellers with qualified buyers to maximize the value to both.</p>
        </div>
     </div>
     
     <div className="container-fluid">
        <div className="row p-5">
          <div className='col-md-4'>
          <div className="d-flex flex-row bd-highlight mb-3">
            <div className="p-2 bd-highlight"> <img src='./images/light.png' />  </div>
            <div className="p-2 bd-highlight"> <p className={styles.number}>1</p>  </div>
            </div>
            <p className={styles.boxheading}>Led Ask Possible Mistress</p>
            <p className={styles.boxtext}>Connection has put impossible own apartments boisterous. At jointure ladyship an insisted so humanity he. Friendly bachelor entrance to on by.</p>
          </div>
          <div className='col-md-4'>
          <div className="d-flex flex-row bd-highlight mb-3">
            <div className="p-2 bd-highlight"> <img src='./images/message.png' />  </div>
            <div className="p-2 bd-highlight"> <p className={styles.number}>2</p>  </div>
            </div>
            <p className={styles.boxheading}>Led Ask Possible Mistress</p>
            <p className={styles.boxtext}>Connection has put impossible own apartments boisterous. At jointure ladyship an insisted so humanity he. Friendly bachelor entrance to on by.</p>
          </div>
          <div className='col-md-4'>
          <div className="d-flex flex-row bd-highlight mb-3">
            <div className="p-2 bd-highlight"> <img src='./images/pencil.png' />  </div>
            <div className="p-2 bd-highlight"> <p className={styles.number}>3</p>  </div>
            </div>
            <p className={styles.boxheading}>Led Ask Possible Mistress</p>
            <p className={styles.boxtext}>Connection has put impossible own apartments boisterous. At jointure ladyship an insisted so humanity he. Friendly bachelor entrance to on by.</p>
          </div>
        </div>
     </div>

     <div className="container">
        <div className='row'>
            <div className='col-md-5'>
                <img src='./images/about.jpg' className='img-fluid' />
            </div>
            <div className='col-md-7'>
               <h1 className={styles.aboutheading}>There is a huge market for used electronic devices. In fact, resellers report shortages in supply to fill this surging demand.</h1>
               <p className={styles.abouttext}>But selling used computers, phones, tablets and wearables has historically been a hassle for sellers - requiring significant research, vendor comparisons and repetitive conversations. That’s why companies have closets full of devices decreasing in value every day.

                Assembly bridges the gap between supply and demand by matching more buyers with more sellers, introducing competition that makes it easier for businesses to recover the maximum value of their used electronic devices. Our smart-matching technology reduces the time traditionally involved in IT asset disposition, making the process more seamless and transparent for everyone involved.</p>
            </div>
        </div>
     </div>

     <div className='container'>
        <div className='row mt-5'>
            <div className='col-md-2'></div>
            <div className={'col-md-8 text-center p-4 '+styles.sellbox}>
            <img src='./images/greenStars.svg' />
            <p className={styles.custmrowh}>Connect with Buyers Today</p>
            <p className={styles.custmrowdesc}>You set the terms. You define what qualities you need in a buyer. You are in control. Our simple, guided listing process collects the information buyers need - and only the information needed - in a streamlined listing.</p> 
            <div className='p-5'>
            <button className={'btn '+styles.sellbtn}>Signup <i className="fa fa-paper-plane-o" aria-hidden="true"></i></button>
            </div>  
            </div>
            <div className='col-md-2'></div>
        </div>
        </div>


    <Frountfooter />
    </> );
}
 
export default About;