import React, { useState } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import "@fontsource/roboto";
import "@fontsource/nunito-sans";
import "@fontsource/dm-sans";
import styles from './buyerdashboard.module.css';
import Footer from '../../footer/Footer';
import { postData } from '../../services/services';
import { useNavigate } from 'react-router-dom';
import Header from '../header/Header';
const Buyerdashboard = () => {
    let naviagate = useNavigate();
   const [activeclass, setActiveclass] = useState(styles.navicon)

  


    return ( <>
     <Header />

    <div className="container mt-5">
        <div className="row ">
            <div className='col-md-4 text-center'>
                <div className={styles.rightprofile}> <img src='./images/Avatar.png' className={styles.profileimage} /> </div>
                <div className={styles.buyername}> <h1>Charles Robbie</h1> </div>
                 <p className={styles.buyerlocation}>New York, USA</p>
                <div className={styles.bitcounter}> 
                    <ul>
                        <li>Total Bids</li>
                        <li>Bids Winning</li>
                        <li>Bid Loosing</li>
                    </ul>
                </div>
                <div className={styles.bitcounrtrnum}> 
                    <ul>
                        <li>120</li>
                        <li>30</li>
                        <li>90</li>
                    </ul>
                </div>
            </div>
            <div className='col-md-4 text-center p-4 mt-2'>
                <div className={styles.card}>
                    <h2>Pending Bids</h2>
                    <h1>23 Bids</h1>
                    <p>Total pending Bids</p>
                    <p className={styles.bitprice}>$659</p>
                    <div className='p-3'>
                    <button className={`btn `+styles.dashbutton}>See All Pending Bids</button>
                    </div>
                    
                </div>
            </div>
            <div className='col-md-4 text-center p-4 mt-2'>
                <div className={styles.card}>
                    <h2>Pending Bids</h2>
                    <h1>23 Bids</h1>
                    <p>Total pending Bids</p>
                    <p className={styles.bitprice}>$659</p>
                    <div className='p-3'>
                    <button className={`btn `+styles.dashbutton}>See All Pending Bids</button>
                    </div>
                    
                </div>
            </div>

        </div>
    </div>

    <div className='container'>
        <div className='row'>
            
            <div className='col-md-4'>
            <div className={styles.sidebar}>
                <ul>
                    
                <li > <img src='./images/Vector (1).png' /> Bashboard</li>
                    <li > <img src='./images/Vector (2).png' /> Profile</li>
                    <li> <img src='./images/Vector (3).png' /> Library</li>
                    <li><img src='./images/Vector (4).png' /> Security</li>
                    <li><img src='./images/Vector (5).png' /> Shedules</li>
                    <li><img src='./images/Vector (7).png' /> Payouts</li>
                    <li><img src='./images/Vector (8).png' /> Settings</li>
                </ul>
                <div className={styles.updratebtn}>
                    <button className='btn'>Upgrade to PRO to get access to all features!</button>
                </div>
            </div>
            </div>

            <div className={`col-md-8 `+styles.dashboardlist}>
                <h1>My Bids</h1>
                 <div className='row'>
                     <div className='col-md-1'>
                         <div className={styles.listimagecircle}>
                         <img src='./images/Avatar.png' className={styles.listimage} />
                         </div>
                       
                     </div>
                     <div className='col-md-9'>
                        <p className={styles.listtext}>Vintage Typewriter to post awesome stories about UI design and webdev.</p>
                        <p className={styles.closetime}>Close in: 0hr 20min</p> 
                     </div>
                     <div className='col-md-2'>
                         <button className={`btn `+styles.listbuttons}>View Details</button>
                     </div>
                 </div>
                 <div className='row mt-5'>
                     <div className='col-md-1'>
                         <div className={styles.listimagecircle}>
                         <img src='./images/Avatar.png' className={styles.listimage} />
                        
                         </div>
                       
                     </div>
                     <div className='col-md-9'>
                        <p className={styles.listtext}>Vintage Typewriter to post awesome stories about UI design and webdev.</p>
                        <p className={styles.closetime}>Close in: 0hr 20min</p> 
                     </div>
                     <div className='col-md-2'>
                         <button className={`btn `+styles.listbuttons}>View Details</button>
                     </div>
                 </div>

                 <div className='row mt-5'>
                     <div className='col-md-1'>
                         <div className={styles.listimagecircle}>
                         <img src='./images/Avatar.png' className={styles.listimage} />
                        
                         </div>
                       
                     </div>
                     <div className='col-md-9'>
                        <p className={styles.listtext}>Vintage Typewriter to post awesome stories about UI design and webdev.</p>
                        <p className={styles.closetime}>Close in: 0hr 20min</p> 
                     </div>
                     <div className='col-md-2'>
                         <button className={`btn `+styles.listbuttons}>View Details</button>
                     </div>
                 </div>
                 <div className='row mt-5'>
                     <div className='col-md-1'>
                         <div className={styles.listimagecircle}>
                         <img src='./images/Avatar.png' className={styles.listimage} />
                        
                         </div>
                       
                     </div>
                     <div className='col-md-9'>
                        <p className={styles.listtext}>Vintage Typewriter to post awesome stories about UI design and webdev.</p>
                        <p className={styles.closetime}>Close in: 0hr 20min</p> 
                     </div>
                     <div className='col-md-2'>
                         <button className={`btn `+styles.listbuttons}>View Details</button>
                     </div>
                 </div>

                 <div className='row mt-5'>
                     <div className='col-md-1'>
                         <div className={styles.listimagecircle}>
                         <img src='./images/Avatar.png' className={styles.listimage} />
                        
                         </div>
                       
                     </div>
                     <div className='col-md-9'>
                        <p className={styles.listtext}>Vintage Typewriter to post awesome stories about UI design and webdev.</p>
                        <p className={styles.closetime}>Close in: 0hr 20min</p> 
                     </div>
                     <div className='col-md-2'>
                         <button className={`btn `+styles.listbuttons}>View Details</button>
                     </div>
                 </div>
                 <div className='row mt-5'>
                     <div className='col-md-1'>
                         <div className={styles.listimagecircle}>
                         <img src='./images/Avatar.png' className={styles.listimage} />
                        
                         </div>
                       
                     </div>
                     <div className='col-md-9'>
                        <p className={styles.listtext}>Vintage Typewriter to post awesome stories about UI design and webdev.</p>
                        <p className={styles.closetime}>Close in: 0hr 20min</p> 
                     </div>
                     <div className='col-md-2'>
                         <button className={`btn `+styles.listbuttons}>View Details</button>
                     </div>
                 </div>
                 <div className='row mt-5'>
                     <div className='col-md-1'>
                         <div className={styles.listimagecircle}>
                         <img src='./images/Avatar.png' className={styles.listimage} />
                        
                         </div>
                       
                     </div>
                     <div className='col-md-9'>
                        <p className={styles.listtext}>Vintage Typewriter to post awesome stories about UI design and webdev.</p>
                        <p className={styles.closetime}>Close in: 0hr 20min</p> 
                     </div>
                     <div className='col-md-2'>
                         <button className={`btn `+styles.listbuttons}>View Details</button>
                     </div>
                 </div>
                 <div className='row mt-5'>
                     <div className='col-md-1'>
                         <div className={styles.listimagecircle}>
                         <img src='./images/Avatar.png' className={styles.listimage} />
                        
                         </div>
                       
                     </div>
                     <div className='col-md-9'>
                        <p className={styles.listtext}>Vintage Typewriter to post awesome stories about UI design and webdev.</p>
                        <p className={styles.closetime}>Close in: 0hr 20min</p> 
                     </div>
                     <div className='col-md-2'>
                         <button className={`btn `+styles.listbuttons}>View Details</button>
                     </div>
                 </div>
                 
            </div>
        </div>
    </div>
    <Footer />
    </>  );
}
 
export default Buyerdashboard;