import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import "@fontsource/roboto";
import "@fontsource/nunito-sans";
import "@fontsource/dm-sans";
import styles from './graddingscale.module.css'
import Header from '../header/Header';
import Footer from '../../footer/Footer';

const Gradingscale = () => {
    return ( <>
    <Header />
  <div className="container">
    <div className='row p-5'>
        <div className='col-md-6 mt-5'>
            <p className={styles.slidertoptext}>DLS GRADING SCALE</p>
            <h1 className={styles.siderheading}>DLS GRADING SCALE</h1>
            <p className={styles.sliderdesc}>The Grade definitions, cosmetic guidelines, and functional testing for TPS-180 Grading and DLS (Formerly HYLA) Grading are slightly different.</p>
        </div>
        <div className='col-md-6 text-end'>
            <img src='./images/gradingslider.png' />
        </div>

        <div className='col-md-12 mt-5'>
            <h2 className={styles.heading2}>Devices are thoroughly inspected and receive a grade based on cosmetic condition and functionality.</h2>
            <p className={styles.sliderdesc2}>Grading scales are specific to each device category</p>
        </div>
    </div>
  </div>

  <div className="container">
    <div className='row '>
    <div className={'col-md-4'}>
            <div className={'d-flex flex-row '+styles.maincol}>
            <div className="p-1">
                <p className={styles.text11}>Wearables Grades</p>
                <p className={styles.text111}>Lorem ipsum dolor sit amet, consectetur adipiscing elit, </p>
            </div>
            <div className="p-2 mt-4"><img src='./images/img1.png' height={100} width={100}></img></div>
            </div>
           
        </div>
        <div className={'col-md-4'}>
            <div className={'d-flex flex-row '+styles.maincol}>
            <div className="p-1">
                <p className={styles.text11}>Wearables Grades</p>
                <p className={styles.text111}>Lorem ipsum dolor sit amet, consectetur adipiscing elit, </p>
            </div>
            <div className="p-2 mt-4"><img src='./images/img1.png' height={100} width={100}></img></div>
            </div>
           
        </div>
        <div className={'col-md-4'}>
            <div className={'d-flex flex-row '+styles.maincol}>
            <div className="p-1">
                <p className={styles.text11}>Wearables Grades</p>
                <p className={styles.text111}>Lorem ipsum dolor sit amet, consectetur adipiscing elit, </p>
            </div>
            <div className="p-2 mt-4"><img src='./images/img1.png' height={100} width={100}></img></div>
            </div>
           
        </div>
    </div>
  </div>
  <div className='container mt-5'>
    <h1 className={ 'text-center '+styles.gadesheading}>Smartphones and Tablets Grades</h1>
    <p className={'text-center '+styles.downp}>Here is a full breakdown of our stock grades with example videos.
See FUNCTIONAL TESTING to learn what tests we run to determine our grades.

All devices have the OEM locks disabled and have been data cleared.</p>
  </div>

  <div className="container">
    <div className={'row '+styles.productlist}>
        
            <div className='col-md-3'> <p className={styles.rating}>AA+</p> </div>
            <div className='col-md-6'>
                 <h1 className={styles.productheading}>Like New, Fully Functional Devices</h1>
                 <p className={styles.producdetails}>Like-new devices from Buyer’s Remorse programs typically returned by consumers within 14 days of purchase.</p>
             </div>
            <div className={'col-md-3 text-center '+styles.rproductsection}> <img src='./images/pimage.png' className='img-fluid' /> </div>
        
    </div>

    <div className={'row '+styles.productlist}>
        
            <div className='col-md-3'> <p className={styles.rating}>AA+</p> </div>
            <div className='col-md-6'>
                 <h1 className={styles.productheading}>Like New, Fully Functional Devices</h1>
                 <p className={styles.producdetails}>Like-new devices from Buyer’s Remorse programs typically returned by consumers within 14 days of purchase.</p>
             </div>
            <div className={'col-md-3 text-center '+styles.rproductsection}> <img src='./images/pimage.png' className='img-fluid' /> </div>
        
    </div>

    <div className={'row '+styles.productlist}>
        
            <div className='col-md-3'> <p className={styles.rating}>AA+</p> </div>
            <div className='col-md-6'>
                 <h1 className={styles.productheading}>Like New, Fully Functional Devices</h1>
                 <p className={styles.producdetails}>Like-new devices from Buyer’s Remorse programs typically returned by consumers within 14 days of purchase.</p>
             </div>
            <div className={'col-md-3 text-center '+styles.rproductsection}> <img src='./images/pimage.png' className='img-fluid' /> </div>
        
    </div>

    <div className={'row '+styles.productlist}>
        
            <div className='col-md-3'> <p className={styles.rating}>AA+</p> </div>
            <div className='col-md-6'>
                 <h1 className={styles.productheading}>Like New, Fully Functional Devices</h1>
                 <p className={styles.producdetails}>Like-new devices from Buyer’s Remorse programs typically returned by consumers within 14 days of purchase.</p>
             </div>
            <div className={'col-md-3 text-center '+styles.rproductsection}> <img src='./images/pimage.png' className='img-fluid' /> </div>
        
    </div>
  </div>
    <Footer />
    </> );
}
 
export default Gradingscale;