import React,{useState} from 'react';
import { Formik } from 'formik';
import { postData,getData } from '../../admin/services/services';
import { useNavigate } from 'react-router-dom';
import 'react-loading-skeleton/dist/skeleton.css'
import Swal from 'sweetalert2';
import "@fontsource/roboto";
import "@fontsource/nunito-sans";
import "@fontsource/dm-sans";
import Frountfooter from '../footer/footer';
import Frountheader from '../header/header';
import styles from './contact.module.css';
const Contactus = () => {
    const [errormsg , setErrormsg] = useState('')

    let navigate = useNavigate()

    function faqs(){
      navigate('/faqs')
    }


    return ( <>
    <Frountheader />
     <div className='container'>
        <div className='row pt-5'>
            <div className='col-md-6'>
            <h1 className={styles.toptext}>Let's Connect</h1>
            </div>
            <div className='col-md-6'>
           

            <div className="d-flex flex-row-reverse bd-highlight">
            <div className="p-2 bd-highlight"><button className={styles.signupbtn} onClick={faqs} >View FAQ</button></div>
            <div className="p-2 bd-highlight"><p className={styles.topright}>Still have a question?</p></div>
           
            
            </div>
            
           
            </div>
        </div>
     </div>
     <div className={'container-fluid '+styles.topsection}>
        <div className='row'>
            <p className={styles.topsectiontext}>Have a question for the Assembly team? Need more details on how to list your devices for sale? Ready to begin buyer registration? Give us a shout.</p>
        </div>
     </div>
     <div className="container-fluid !direction !spacing">
       
        <div className="row">
            <div className='col-md-1'></div>
            <div className='col-md-10 ' >
               <Formik
       initialValues={{ company_name: '', first_name: '',last_name: '',hearAboutUs:'',message:'',email:'' }}
       validate={values => {
         const errors = {};

         if (!values.email) {
            errors.email = 'Required';
          } else if (
            !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)
          ) {
            errors.email = 'Invalid email address';
          }
         if (!values.company_name) {
           errors.company_name = 'Required';
         }
         
         if (!values.first_name) {
            errors.first_name="Required" 
         }
         if (!values.last_name) {
            errors.last_name="Required" 
         }
       
         if (!values.hearAboutUs) {
            errors.hearAboutUs="Required" 
         }
         if (!values.message) {
            errors.message="Required"
         }
        
         
      
         return errors;
       }}
       onSubmit={(values, { setSubmitting }) => {

        var  url ='buyer/profile'
        var response =  postData(url,values);
        response.then(response=>{

            if (response.status == '200') {
               Swal.fire({
                  position: 'center',
                  icon: 'success',
                  title: 'Buyer Profile Created Successfully',
                  showConfirmButton: false,
                  timer: 1500
                }).then(()=>{
                  navigate('/buyerdashboard')
                })
               
            }
        })
        .catch(error => {
            if (error.response.data.error == 'Unauthorized') {
              
                setErrormsg('Incorrect Username Or Password')
            }else{
                setErrormsg('Incorrect Username Or Password') 
               
            }
          
       })

           setSubmitting(false);
        
       }}
     >
       {({
         values,
         errors,
         touched,
         handleChange,
         handleBlur,
         handleSubmit,
         isSubmitting,
         /* and other goodies */
       }) => (
         <form onSubmit={handleSubmit}>
             <div className={styles.form}>
                  <div className={`row `+styles.border}>
                      <h1 className={`mt-4 `+styles.peritext}>Contact Form</h1>
                      <div className='col-md-6 text-left p-4'>
                      
                     <div className='form-group mt-3'>
                    <label className={styles.label}> First Name</label>
                    <input className='form-control' type="text" name='first_name'
                     id="firstname" placeholder="Andy"
                     onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.first_name} />
                   <small  className='text-danger'>{errors.first_name && touched.first_name && errors.first_name}</small>
                </div>
                <div className='form-group mt-3'>
             <label className={styles.label}>Email</label>
             <input
             type="email"
             name="email"
             className='form-control'  id="email" placeholder='mail@abc.com'
             onChange={handleChange}
             onBlur={handleBlur}
             value={values.email}
           />
           <small  className='text-danger'>{errors.email && touched.email && errors.email}</small>
             </div>

                <div className='form-group mt-3'>
                    <label className={styles.label}>I’m interested in becoming a</label>
                    <select id="hear_about_us" name="hearAboutUs" placeholder="-" 
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.hearAboutUs}
                    className="form-select">
                        <option value="default" disabled="">Select Your Option</option>
                        <option value="seller">Seller</option>
                        <option value="buyer">Buyer</option>
                        </select>
                        <small  className='text-danger'>{errors.hearAboutUs && touched.hearAboutUs && errors.hearAboutUs}</small>
                </div>
                      </div>
                      <div className='col-md-6 text-right p-4'>
                     <div className='form-group mt-3'>
                    <label className={styles.label}>Last Name</label>
                    <input className='form-control' type="text" name='last_name' id="lastname" 
                    placeholder='Mayberry'
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.last_name}
                    />
                   <small  className='text-danger'>{errors.last_name && touched.last_name && errors.last_name}</small>
                </div>

                <div className='form-group mt-3'>
                    <label className={styles.label}>Company Name</label>
                    <input className='form-control' name='company_name' 
                    type="text" id="companyname" placeholder='ABC.Co'
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.company_name}
                    />
                    <small  className='text-danger'>{errors.company_name && touched.company_name && errors.company_name}</small>
                     </div>
                
                     <div className='form-group mt-3'>
                    <label className={styles.label}>Message</label>
                    <textarea className='form-control' name='message' 
                    type="text" id="companyname" placeholder='Message'
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.message}
                    />
                    <small  className='text-danger'>{errors.message && touched.message && errors.message}</small>
                     </div>
                      </div>
                      <div className="row mb-4">
        <div className='col-md-4'></div>
            <div className='col-md-4'>
        
                    <div className='form-group mt-3 text-center'>
                   
                   <button  className={`form-control btn `+styles.createbtn} disabled={isSubmitting} type="submit">Save</button>
                     
                    
                </div>
            
        </div>
        <div className='col-md-4'></div>
        </div>
                  </div>
                  
               </div>
               
               <div className="container-fluid !direction !spacing">
        
    </div>
         </form>
       )}
     </Formik>

    

    

            </div>
            <div className='col-md-1'></div>
        </div>
    </div>
    <Frountfooter/>
    </> );
}
 
export default Contactus;