import React, { useState } from 'react';
import Frountfooter from '../footer/footer';
import Frountheader from '../header/header';
import { Modal, Button } from "react-bootstrap";
import styles from './main.module.css';
import { useNavigate } from 'react-router-dom';
const Home = () => {
    let navigate = useNavigate();
    const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    function buyersignup(){
        navigate('/buyersignup')
    }

    function sellerSignup(){
       navigate('/sellersignup') 
    }

    return ( <>
    <Frountheader />
    <div className='container-fluid'>
        <div className='row'>
            <div className='col-md-3'>
                <div className={styles.coverimg}>
                <img src='./images/hero_left.png' />
                </div>
            </div>
            <div className='col-md-6'>
            <h1 className={'mt-5 '+styles.text4xl}>The simplest way to get more value for your company’s used devices.</h1>
            <p className={styles.text1xl}>Let our nationwide network of qualified buyers compete for your IT assets.</p>
            <div className='text-center mt-4 mb-5'>
            <p>Learn More</p>
            <button className={styles.signupbtn} onClick={handleShow}>Signup</button>
            </div>
            </div>
            <div className='col-md-3'>
            <div className={styles.coverimg}>
                <img src='./images/hero_right.png' />
                </div>
            </div>
        </div>
    </div>
    <div className='container-fluid'>
        <div className='row'>
            <div className='col-md-2'>
                
            </div>
            <div className='col-md-8'>
            <div className={styles.shadow2xl}>
            <iframe src="https://player.vimeo.com/video/597341260?h=f00d813da8&amp;byline=0&amp;portrait=0" frameBorder="0" allow="autoplay; fullscreen; picture-in-picture"  allowFullScreen="" style={{width:'100%',height:'100%', boxShadow:'0 25px 50px -12px rgba(0,0,0,0.25)'}} ></iframe>
            </div>
            </div>
            <div className='col-md-2'>
            
            </div>
        </div>
    </div>
    <div className={'container-fluid '+styles.toassBlue}>
        <div className='row'>
        <h2 className={'mt-5 '+styles.textBlue4xl}>Hassle-Free Asset Disposition</h2>
         <div className='container p-5'>
             <div className='row'>
         <div className='col-md-4'>
            <img src='./images/yellowClipboard.svg' />
            <p className={styles.custmrowh}>Create a Custom Listing</p>
            <p className={styles.custmrowdesc}>You set the terms. You define what qualities you need in a buyer. You are in control. Our simple, guided listing process collects the information buyers need - and only the information needed - in a streamlined listing.</p>
        </div>
        <div className='col-md-4'>
        
            <img src='./images/feature1blue.svg' />
            <p className={styles.custmrowh}>Create a Custom Listing</p>
            <p className={styles.custmrowdesc}>You set the terms. You define what qualities you need in a buyer. You are in control. Our simple, guided listing process collects the information buyers need - and only the information needed - in a streamlined listing.</p>
        </div>
        <div className='col-md-4'>

        <img src='./images/greenStars.svg' />
            <p className={styles.custmrowh}>Create a Custom Listing</p>
            <p className={styles.custmrowdesc}>You set the terms. You define what qualities you need in a buyer. You are in control. Our simple, guided listing process collects the information buyers need - and only the information needed - in a streamlined listing.</p>
        </div>
         </div>
         </div>
        </div>
    </div>

    <div className='container-fluid'>
      <div className='row mt-5'>
          <p className={styles.bestsellp}>Competitive recovery values provide more to reinvest in your business.</p>
          <p className={styles.bestsellh}>The Best Way to Buy and Sell Used Devices</p>
      </div>
    </div>
    <div className='container'>
      <div className='row mt-5 text-center'>
          <div className={'col-md-5 '+styles.sellbox}>
          <img src='./images/greenStars.svg' />
            <p className={styles.custmrowh}>I Sell Part Time</p>
            <p className={styles.custmrowdesc}>You set the terms. You define what qualities you need in a buyer. You are in control. Our simple, guided listing process collects the information buyers need - and only the information needed - in a streamlined listing.</p> 
            <div className='p-5'>
            <button className={'btn '+styles.sellbtn}>See Auctions For Me</button>
            </div>
            
          </div>
          <div className='col-md-2'></div>
          <div className={'col-md-5 '+styles.sellbox}>
          <img src='./images/greenStars.svg' />
            <p className={styles.custmrowh}>I Sell Part Time</p>
            <p className={styles.custmrowdesc}>You set the terms. You define what qualities you need in a buyer. You are in control. Our simple, guided listing process collects the information buyers need - and only the information needed - in a streamlined listing.</p> 
            <div className='p-5'>
            <button className={'btn '+styles.sellbtn}>See Auctions For Me</button>
            </div>
            
          </div>
            <div className='text-center'>
            <button className={'btn mt-5  '+styles.seeauctionbtn}>See Auctions For Me  <i className="fa fa-paper-plane-o" aria-hidden="true"></i> </button>
            </div>
      </div>
    </div>
    <div className='container-fluid'>
        <div className='row mt-5'>
            <div className={styles.faqtop} style={{backgroundImage:`url('./images/Vector 111.png')`}}>
            <p className={'mt-5 '+styles.bestsellh}>Frequently Asked Questions</p>
            </div>
        <div  className='col-md-6' style={{backgroundColor:'#02CBA7'}}>
            <ul className={'p-5 '+styles.questionlist}>
                <li>How long does it take to create a listing?  <span className={styles.arrow}><i className="fa fa-long-arrow-right" aria-hidden="true"></i></span> </li>
                <li>What’s the minimum amount of devices that can be listed? <span className={styles.arrow}><i className="fa fa-long-arrow-right" aria-hidden="true"></i></span></li>
                <li>How long does it take to create a listing? <span className={styles.arrow}><i className="fa fa-long-arrow-right" aria-hidden="true"></i></span></li>
                <li>What’s the minimum amount of devices that can be listed? <span className={styles.arrow}><i className="fa fa-long-arrow-right" aria-hidden="true"></i></span></li>
                <li>How long does it take to create a listing?<span className={styles.arrow}><i className="fa fa-long-arrow-right" aria-hidden="true"></i></span></li>
                <li>What’s the minimum amount of devices  <span className={styles.arrow}><i className="fa fa-long-arrow-right" aria-hidden="true"></i></span></li>
    
            </ul>
        </div>
        <div  className='col-md-6' style={{backgroundColor:'#F2F2F2'}}>
        <ul className={styles.anslist}>
                <li>How long does it take to create a listing? </li>
                <li>What’s the minimum amount of devices that can be listed? </li>
                <li>How long does it take to create a listing? </li>
                <li>What’s the minimum amount of devices that can be listed?</li>
                <li>What’s the minimum  that can be listed?</li>
                <li>What’s the minimum amount of devices</li>
              
            </ul>
        </div>
        </div>
    </div>
    <div className='container'>
        <div className='row mt-5'>
            <div className='col-md-2'></div>
            <div className={'col-md-8 text-center p-4 '+styles.sellbox}>
            <img src='./images/greenStars.svg' />
            <p className={styles.custmrowh}>Connect with Buyers Today</p>
            <p className={styles.custmrowdesc}>You set the terms. You define what qualities you need in a buyer. You are in control. Our simple, guided listing process collects the information buyers need - and only the information needed - in a streamlined listing.</p> 
            <div className='p-5'>
            <button className={'btn '+styles.sellbtn}>Connect with Buyers Today <i className="fa fa-paper-plane-o" aria-hidden="true"></i></button>
            </div>  
            </div>
            <div className='col-md-2'></div>
        </div>
        
<Modal size="lg" show={show} onHide={handleClose} className={styles.modelcont}>
        <Modal.Header className={styles.logoutmodel} closeButton>
          <Modal.Title>Signup</Modal.Title>
        </Modal.Header>
        <Modal.Body>
        <div className='row'>
          <div className='col-md-12'>
          <div className='row'>
              <div className={'col-md-6 text-center '+styles.border}>
                  <img src='./images/bank.svg' />
                  <h1 className={styles.buyertext}>I'm a Buyer</h1>
                  <p className={styles.textgrey}>I want to purchase electronics in bulk.</p>
                  <button className={'btn mb-5 '+styles.signsellbtn} onClick={buyersignup}>
              <div className="my-auto">Buyer Application</div>
              <p className={'mt-4  '+styles.textgrey}>Learn More <i className="fa fa-angle-double-right" aria-hidden="true"></i></p>
              </button>
              </div>
              <div className='col-md-6 text-center'>
                   <img src='./images/phones.svg' />
                  <h1 className={styles.buyertext}>I'm a Seller</h1>
                  <p className={styles.textgrey}>I have electronics to sell.</p>
                  <button className={'btn mb-5 '+styles.signsellbtn} onClick={sellerSignup}>
              <div className="my-auto">Seller Signup*</div>
              <p className={'mt-4  '+styles.textgrey}>Learn More <i className="fa fa-angle-double-right" aria-hidden="true"></i></p>
              </button>
              

              </div>
              <div className='mt-3 text-center'>
              <p className={styles.textgrey}>* Currently only accepting sellers located in the United States</p>
              </div>
          </div>
          </div>
        </div>
        

        </Modal.Body>
        
      </Modal>
    </div>
    <Frountfooter />
    </> );
}
 
export default Home;<>
</>