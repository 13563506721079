import React,{useState,useEffect} from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import "@fontsource/roboto";
import "@fontsource/nunito-sans";
import googlelogo from './icons8-google-48.png'
import styles from './login.module.css';
import {useNavigate } from 'react-router-dom';
import { Formik } from 'formik';
import { postData } from '../../services/services';



const Sellerlogin = () => {
   
    

    let navigate = useNavigate()
    const [domainid,setDomainid] = useState('');
    const [emailerror, setEmailerror] = useState('');
   const  [password,setPassword] = useState('');
   const [passwordval , setPasswordval] = useState('')
   const [errormsg , setErrormsg] = useState('')
   const [loading, setLoading] = useState(false)

 
    
    function redgister(){
        navigate('/sellersignup')
    }
    
   
    useEffect(()=>{

      
     
    },[] )

    return ( <>
    <div className="container-fluid !direction !spacing">
    <div className="row" >
        <div className={styles.topbar}>
            <p className={styles.toptext}>READY2RESALE</p>
        </div>
    </div>
    </div>
    
    <div className="container-fluid !direction !spacing">
        <div className="row">
            <div className='col-md-4'></div>
            <div className='col-md-4 ' >
                <div className='text-center'>
                <h1 className={styles.loginheading}>Login to your Account</h1>
                <p className={styles.logintext}>See what is going on with your business</p>
                <div className='form-group'>
                    
                    <button className={`form-control btn border googlebtn `+styles.googlebtn}><img src={googlelogo} className={styles.logosize} />&nbsp;&nbsp;&nbsp;  Continue with Google</button>
                    <p className={`mt-4 `+styles.p}>------------- or Sign in with Email -------------</p>
                </div>
                </div>
              
               <div className={styles.form}>
               <Formik
       initialValues={{ email: '', password: '',name: 'NewSeller' }}
       validate={values => {
         const errors = {};
         if (!values.email) {
           errors.email = 'Required';
         } else if (
           !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)
         ) {
           errors.email = 'Invalid email address';
         }
         
         
         if (!values.password) {
            errors.password="Required" 
         }
         return errors;
       }}
       onSubmit={(values, { setSubmitting }) => {

        var  url ='seller/login'
        var response =  postData(url,values);
        response.then(response=>{

          if (response.status == '200') {
            localStorage.setItem('access_token',response.data.access_token)
            navigate('/sellerregistration')
          }
            
        })
        .catch(error => {
            if (error.response.data.error == 'Unauthorized') {
                setLoading(false)
                setErrormsg('Incorrect Username Or Password')
            }else{
                setErrormsg('Incorrect Username Or Password') 
                setLoading(false)
            }
          
       })

           setSubmitting(false);
        
       }}
     >
       {({
         values,
         errors,
         touched,
         handleChange,
         handleBlur,
         handleSubmit,
         isSubmitting,
         /* and other goodies */
       }) => (
         <form onSubmit={handleSubmit}>
             <div className='form-group'>
             <label className={styles.label}>Email</label>
             <input
             type="email"
             name="email"
             className='form-control'  id="email" placeholder='mail@abc.com'
             onChange={handleChange}
             onBlur={handleBlur}
             value={values.email}
           />
           <small  className='text-danger'>{errors.email && touched.email && errors.email}</small>
             </div>
         <div className='form-group mt-3'>
         <label className={styles.label}>Password</label>
       
           <input
             type="password"
             name="password"
             className='form-control'  id="password" placeholder='***********'
             onChange={handleChange}
             onBlur={handleBlur}
             value={values.password}
           />
           <small className='text-danger'>{errors.password && touched.password && errors.password}</small>
           </div>
           <input type="hidden" name='name' value={values.name} onChange={handleChange}></input>
           <div className='form-group mt-3 text-center'>
                   
                   <button  className={`form-control btn `+styles.loginpbtn} disabled={isSubmitting} >Create</button>
                   <small className='text-danger'>{errormsg}</small>
                   <p className={`mt-3 `+styles.alreadyacc} > <span> Not Registered Yet? </span>  &nbsp;&nbsp;&nbsp;<span className={styles.createacctext} onClick={redgister} >Create an account</span></p>
                </div>
         </form>
       )}
     </Formik>
                   
                </div>
               </div>
                
            </div>
            <div className='col-md-4'></div>
      
        </div>
    
    </> );
}
 
export default Sellerlogin;