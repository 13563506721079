import React, { useState } from 'react';
import Frountfooter from '../footer/footer';
import Frountheader from '../header/header';
import styles from './product.module.css'
import "@fontsource/roboto";
import "@fontsource/nunito-sans";
import "@fontsource/dm-sans";
const Products = () => {
     const [showsubmenu, setShowsubmenu] = useState('none');
        function sowSubcat(){
          setShowsubmenu('block')
            }
    return ( <>
    <Frountheader />

    <div className='container'>
        <div className='row'>
            <div className='col-md-3 mt-5'>
                <div className={styles.productsidebar}>
                <ul className={styles.productsidebar2}>
                    <p>All Categories</p>
                    <li onClick={sowSubcat}>Electronic

                        <ul className={styles.productinnerchild} style={{display:`${showsubmenu}`}} id="childcate">
                            <li>asdf</li>
                            <li>asdf</li>
                            <li>asdf</li>

                        </ul>
                    </li>
                   
                </ul>
                </div>
            
            </div>
            <div className='col-md-9'>
                <div className={'row mt-5 '+styles.productgridbox}>
                <div className='col-md-4 p-3'>
                    <div className={styles.productimg}>
                        <img src='./images/image 10.png' width="200px" height="250px" />
                    </div>
                </div>
                <div className='col-md-8'>
                    <p className={styles.phoneheading}>Google Pixel 6 5G, 6.4" 8GB RAM 256GB ROM,Nano SIM - Stormy Black</p>
                    <div className={'d-flex bd-highlight '+styles.productdetails}>
                    <div className={'p-2 flex-fill bd-highlight '+styles.productmodel}>₦570000</div>
                    <div className={'p-2 flex-fill bd-highlight '+styles.productmodel2}>₦520,000</div>
                    <div className={'p-2 flex-fill bd-highlight '+styles.productmodel3}>ADD TO CART</div>
                    </div>
                </div>
                </div>
                <div className={'row mt-5 '+styles.productgridbox}>
                <div className='col-md-4 p-3'>
                    <div className={styles.productimg}>
                        <img src='./images/image 10.png' width="200px" height="250px" />
                    </div>
                </div>
                <div className='col-md-8'>
                    <p className={styles.phoneheading}>Google Pixel 6 5G, 6.4" 8GB RAM 256GB ROM,Nano SIM - Stormy Black</p>
                    <div className={'d-flex bd-highlight '+styles.productdetails}>
                    <div className={'p-2 flex-fill bd-highlight '+styles.productmodel}>₦570000</div>
                    <div className={'p-2 flex-fill bd-highlight '+styles.productmodel2}>₦520,000</div>
                    <div className={'p-2 flex-fill bd-highlight '+styles.productmodel3}>ADD TO CART</div>
                    </div>
                </div>
                </div>
                <div className={'row mt-5 '+styles.productgridbox}>
                <div className='col-md-4 p-3'>
                    <div className={styles.productimg}>
                        <img src='./images/image 10.png' width="200px" height="250px" />
                    </div>
                </div>
                <div className='col-md-8'>
                    <p className={styles.phoneheading}>Google Pixel 6 5G, 6.4" 8GB RAM 256GB ROM,Nano SIM - Stormy Black</p>
                    <div className={'d-flex bd-highlight '+styles.productdetails}>
                    <div className={'p-2 flex-fill bd-highlight '+styles.productmodel}>₦570000</div>
                    <div className={'p-2 flex-fill bd-highlight '+styles.productmodel2}>₦520,000</div>
                    <div className={'p-2 flex-fill bd-highlight '+styles.productmodel3}>ADD TO CART</div>
                    </div>
                </div>
                </div>
                <div className={'row mt-5 '+styles.productgridbox}>
                <div className='col-md-4 p-3'>
                    <div className={styles.productimg}>
                        <img src='./images/image 10.png' width="200px" height="250px" />
                    </div>
                </div>
                <div className='col-md-8'>
                    <p className={styles.phoneheading}>Google Pixel 6 5G, 6.4" 8GB RAM 256GB ROM,Nano SIM - Stormy Black</p>
                    <div className={'d-flex bd-highlight '+styles.productdetails}>
                    <div className={'p-2 flex-fill bd-highlight '+styles.productmodel}>₦570000</div>
                    <div className={'p-2 flex-fill bd-highlight '+styles.productmodel2}>₦520,000</div>
                    <div className={'p-2 flex-fill bd-highlight '+styles.productmodel3}>ADD TO CART</div>
                    </div>
                </div>
                </div>
            </div>
        </div>
       
    </div>
    <Frountfooter />
    </> );
}
 
export default Products;