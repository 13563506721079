import React from 'react';
import "@fontsource/roboto";
import "@fontsource/nunito-sans";
import "@fontsource/dm-sans";
import styles from './faqs.module.css'
import Frountheader from '../header/header';
import Frountfooter from '../footer/footer';
import { useNavigate } from 'react-router-dom';
const FAQs = () => {
    let navigate = useNavigate()
    function contact(){
        navigate('/contactus')
    }
    return ( <>
    <Frountheader />
    <div className='container'>
        <div className='row pt-5'>
            <div className='col-md-6'>
            <h1 className={styles.toptext}>Let's Connect</h1>
            </div>
            <div className='col-md-6'>
           

            <div className="d-flex flex-row-reverse bd-highlight">
            <div className="p-2 bd-highlight"><button className={styles.signupbtn} onClick={contact}>Contact Us</button></div>
            <div className="p-2 bd-highlight"><p className={styles.topright}>Still have a question?</p></div>
           
            
            </div>
            
           
            </div>
        </div>
     </div>
     <div className={'container-fluid '+styles.topsection}>
        <div className='row'>
            <p className={styles.topsectiontext}>FAQ</p>
        </div>
     </div>

     <div className='container'>
        <div className='row mt-5'>
            <h1 className={styles.faqsheading}>Auction Basics</h1>
            <p className={styles.faqstext}>If a bid is placed in the last 7 minutes of an auction, the auction end time will extend for an additional 3 minutes. This is called "Popcorn Bidding" and gives all bidders an equal chance of winning an auction if a last minute bid is placed. Popcorn bidding simulates a live auction and prevents other bidders from "sniping" an auction at the last second.

For more information, please read our article on Popcorn Bidding or check out our Knowledge Base articles on How Bidding Works.
</p>
<h1 className={styles.faqsheading}>What if I Have More Questions About an Auction?</h1>
            <p className={styles.faqstext}>All of the information about a specific listing is available on the auction page.

B-Stock Supply auction pages have a button called “Ask Seller A Question” where you can request more information from the seller.

Don’t bid on an auction if you have unanswered questions or are unsure about the purchase.

Lack of information is not a valid reason for cancelling a bid or order.

If you have additional questions, you may reach out to Customer Support.
</p>
<h1 className={styles.faqsheading}>Where Are You Located? Who Sells the Merchandise on the Site?</h1>
            <p className={styles.faqstext}>The auctions on B-Stock Supply are from both small and large businesses liquidating their excess inventory. The auction page and description displays the location of the merchandise.

B-Stock does not own any of the merchandise listed on the site. We are a marketplace connecting buyers and sellers.
</p>
<h1 className={styles.faqsheading}>What Condition Is the Merchandise in? Is It All New?</h1>
            <p className={styles.faqstext}>The condition of the merchandise being sold varies between auctions, and can be anything from new in box to salvage condition. Please view the auction page for listing information and visit our "Conditions" page for more information on each condition type.
</p>
        </div>
         </div>

    <Frountfooter />
    </> );
}
 
export default FAQs;