import React, { useState } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import "@fontsource/roboto";
import "@fontsource/nunito-sans";
import "@fontsource/dm-sans";
import { Nav,Navbar,Container,Modal, Button } from 'react-bootstrap';
import styles from './header.module.css';
import { Link, useNavigate } from 'react-router-dom';
const Frountheader = () => {
let navigate = useNavigate();
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  function buyersignup(){
    navigate('/buyersignup')
}

function sellerSignup(){
   navigate('/sellersignup') 
}

  
    return (  <>
    <div className={'container-fluid '+styles.topbar}>
        <h2 className={styles.toptext}>Welcome to in Your Dream Online Store!</h2>
    </div>
    
    <div className='container-fluid'>
        <div className='row'>
        <Navbar  expand="lg">
  <Container fluid>
    <Navbar.Brand href="#"><img src='./images/logo.png' /></Navbar.Brand>
    <Navbar.Toggle aria-controls="navbarScroll" />
    <Navbar.Collapse id="navbarScroll">
      <Nav
        className="me-auto my-2 my-lg-0"
        style={{ maxHeight: '100px' }}
        navbarScroll
      >
          
      </Nav>
      <div className="d-flex">
      <ul className={'navbar-nav me-auto mb-2 mb-lg-0 '+styles.menu}>
        <li className="nav-item">
          <Link className="nav-link" to="/how" >How Its Works</Link>
        </li>
        <li className="nav-item">
          <Link className="nav-link" to="/about">About</Link>
        </li>
        <li className="nav-item">
          <Link className="nav-link" to="/faqs">FAQ</Link>
        </li>
        <li className="nav-item">
          <Link className="nav-link" to="/contactus">Contact</Link>
        </li>
        <li className="nav-item">
          <Link className="nav-link" to="/login">Login</Link>
          
        </li>
        <li className="nav-item">
        <button className={'btn '+styles.signupbtn} onClick={handleShow}>Sign Up</button>
        </li>
      </ul>
        
      </div>
    </Navbar.Collapse>
  </Container>
</Navbar>
        </div>
        <Modal size="lg" show={show} onHide={handleClose} className={styles.modelcont}>
        <Modal.Header className={styles.logoutmodel} closeButton>
          <Modal.Title>Signup</Modal.Title>
        </Modal.Header>
        <Modal.Body>
        <div className='row'>
          <div className='col-md-12'>
          <div className='row'>
              <div className={'col-md-6 text-center '+styles.border}>
                  <img src='./images/bank.svg' />
                  <h1 className={styles.buyertext}>I'm a Buyer</h1>
                  <p className={styles.textgrey}>I want to purchase electronics in bulk.</p>
                  <button className={'btn mb-5 '+styles.signsellbtn} onClick={buyersignup}>
              <div className="my-auto">Buyer Application</div>
              <p className={'mt-4  '+styles.textgrey}>Learn More <i className="fa fa-angle-double-right" aria-hidden="true"></i></p>
              </button>
              </div>
              <div className='col-md-6 text-center'>
                   <img src='./images/phones.svg' />
                  <h1 className={styles.buyertext}>I'm a Seller</h1>
                  <p className={styles.textgrey}>I have electronics to sell.</p>
                  <button className={'btn mb-5 '+styles.signsellbtn} onClick={sellerSignup}>
              <div className="my-auto">Seller Signup*</div>
              <p className={'mt-4  '+styles.textgrey}>Learn More <i className="fa fa-angle-double-right" aria-hidden="true"></i></p>
              </button>
              

              </div>
              <div className='mt-3 text-center'>
              <p className={styles.textgrey}>* Currently only accepting sellers located in the United States</p>
              </div>
          </div>
          </div>
        </div>
        

        </Modal.Body>
        
      </Modal>
    </div>
    
    </> );
}
 
export default Frountheader;