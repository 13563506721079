import React,{useState,useEffect} from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import "@fontsource/roboto";
import "@fontsource/nunito-sans";
import styles from './sellerrregs.module.css';
import { Link, useNavigate } from 'react-router-dom';
import { Formik } from 'formik';
import { postData,getData } from '../../services/services';
import { Modal } from 'react-bootstrap';
import Skeleton from 'react-loading-skeleton'
import 'react-loading-skeleton/dist/skeleton.css'
import Swal from 'sweetalert2';
const SellerRegistration = () => {

   const [errormsg , setErrormsg] = useState('')

   const [loading, setLoading] = useState(true);
   const [show, setShow] = useState(false);

   const handleClose = () => setShow(false);
   


   let navigate = useNavigate()
   
   function logout(){
     var  url ='buyer/logout'
     var response =  postData(url);
     response.then(response=>{
         navigate('/login')
     })
     .catch(error => {

     })

   }


   useEffect(() => {

   
     var  url ='buyer/islogin'
     var data = '';
       var response =  getData(url,data);
       response.then(response=>{
          setLoading(false)
           setShow(true);
           
       })
       .catch(error => {
          
           if (error.response.data.message == 'user is unauthenticate') {
              navigate('/buyerlogin')
           }
          
         
      })
   },[]);

   return ( <>
   <div className="container-fluid !direction !spacing">
   <div className="row" >
       <div className={styles.topbar}>
           <p className={styles.toptext}><img src='./images/logo.png' /></p>
       </div>
   </div>
   </div>
   
   <div className="container-fluid !direction !spacing">
      
       <div className="row">
           <div className='col-md-1'></div>
           <div className='col-md-10 ' >
              
             
             

             {loading == true? <Skeleton count={20} height={30} /> :<>
             <div className='text-center'>

<h1 className={styles.loginheading}>Welcome to Ready2Resale Supply registration!</h1>
<p className={styles.regstoptext}>Please enter your email address below to get started. If you are already registered on another marketplace within the B-Stock Network, please enter the email address associated with that registration.
Ready2Resale Supply charges a 10% Buyer's Premium fee. For more information, please visit our FAQ.</p>

</div>
              <Formik
      initialValues={{ company_name: '', first_name: '',last_name: '',phone:'',website:'',linkden:'',hearAboutUs:''
   ,address_one:'',address_two:'',city:'',state:'',postal:'',country:'' }}
      validate={values => {
        const errors = {};
        if (!values.company_name) {
          errors.company_name = 'Required';
        }
        
        if (!values.first_name) {
           errors.first_name="Required" 
        }
        if (!values.last_name) {
           errors.last_name="Required" 
        }
        if (!values.phone) {
           errors.phone="Required" 
        }
        if (!values.website) {
           errors.website="Required" 
        }
        if (!values.linkden) {
           errors.linkden="Required" 
        }
        if (!values.hearAboutUs) {
           errors.hearAboutUs="Required" 
        }
        if (!values.address_one) {
           errors.address_one="Required" 
        }
        
        if (!values.city) {
           errors.city="Required" 
        }
        if (!values.state) {
           errors.state="Required" 
        }
        if (!values.postal) {
           errors.postal="Required" 
        }
        if (!values.country) {
           errors.country="Required" 
        }
        return errors;
      }}
      onSubmit={(values, { setSubmitting }) => {

       var  url ='buyer/profile'
       var response =  postData(url,values);
       response.then(response=>{
           if (response.status == '200') {

            Swal.fire({
               position: 'center',
               icon: 'success',
               title: 'Seller Profile Created Successfully',
               showConfirmButton: false,
               timer: 1500
             }).then(()=>{
               navigate('/sellerdashboard')
             })
              
           }
       })
       .catch(error => {
           if (error.response.data.error == 'Unauthorized') {
             
               setErrormsg('Incorrect Username Or Password')
           }else{
               setErrormsg('Incorrect Username Or Password') 
              
           }
         
      })

          setSubmitting(false);
       
      }}
    >
      {({
        values,
        errors,
        touched,
        handleChange,
        handleBlur,
        handleSubmit,
        isSubmitting,
        /* and other goodies */
      }) => (
        <form onSubmit={handleSubmit}>
            <div className={styles.form}>
                 <div className={`row `+styles.border}>
                     <h1 className={`mt-4 `+styles.peritext}>Personal Information</h1>
                     <div className='col-md-6 text-left p-4'>
                     <div className='form-group'>
                   <label className={styles.label}>Company Name</label>
                   <input className='form-control' name='company_name' 
                   type="text" id="companyname" placeholder='ABC.Co'
                   onChange={handleChange}
                   onBlur={handleBlur}
                   value={values.company_name}
                   />
                   <small  className='text-danger'>{errors.company_name && touched.company_name && errors.company_name}</small>
                    </div>
                    <div className='form-group mt-3'>
                   <label className={styles.label}>Contact First Name</label>
                   <input className='form-control' type="text" name='first_name'
                    id="firstname" placeholder="Andy"
                    onChange={handleChange}
                       onBlur={handleBlur}
                       value={values.first_name} />
                  <small  className='text-danger'>{errors.first_name && touched.first_name && errors.first_name}</small>
               </div>
               <div className='form-group mt-3'>
                   <label className={styles.label}>Linkedin Profile</label>
                   <input className='form-control' type="text" name='linkden'
                    id="linkden" placeholder='linkedin.com/me'
                    onChange={handleChange}
                   onBlur={handleBlur}
                   value={values.linkden}
                    />
                  <small  className='text-danger'>{errors.linkden && touched.linkden && errors.linkden}</small>
               </div>
               <div className='form-group mt-3'>
                   <label className={styles.label}>How did you hear about us?</label>
                   <select id="hear_about_us" name="hearAboutUs" placeholder="-" 
                   onChange={handleChange}
                   onBlur={handleBlur}
                   value={values.hearAboutUs}
                   className="form-select">
                       <option value="default" disabled="">Select Your Option</option>
                       <option value="googleads">Google Ads</option>
                       <option value="linkedinads">LinkedIn Ads</option>
                       <option value="socialmedia">Social Media</option>
                       <option value="wordofmouth">Word of Mouth</option>
                       <option value="other">Other</option>
                       </select>
                       <small  className='text-danger'>{errors.hearAboutUs && touched.hearAboutUs && errors.hearAboutUs}</small>
               </div>
                     </div>
                     <div className='col-md-6 text-right p-4'>
                     <div className='form-group'>
                   <label className={styles.label}>Contact Phone</label>
                   <input className='form-control' name='phone' type="number" 
                   id="businessname" placeholder='000-000-0000' 
                   onChange={handleChange}
                   onBlur={handleBlur}
                   value={values.phone}
                   />
                   <small  className='text-danger'>{errors.phone && touched.phone && errors.phone}</small>
                    </div>
                    <div className='form-group mt-3'>
                   <label className={styles.label}>Contact Last Name</label>
                   <input className='form-control' type="text" name='last_name' id="lastname" 
                   placeholder='Mayberry'
                   onChange={handleChange}
                   onBlur={handleBlur}
                   value={values.last_name}
                   />
                  <small  className='text-danger'>{errors.last_name && touched.last_name && errors.last_name}</small>
               </div>
               <div className='form-group mt-3'>
                   <label className={styles.label}>Website</label>
                   <input className='form-control' name='website' type="text"
                    id="website" placeholder='company.com'
                    onChange={handleChange}
                   onBlur={handleBlur}
                   value={values.website}
                    />
                  <small  className='text-danger'>{errors.website && touched.website && errors.website}</small>
               </div>
            
                     </div>
                 </div>
              </div>
              <div className={styles.form}>
                 <div className={`row `+styles.border}>
                     <h1 className={`mt-4 `+styles.peritext}>Address</h1>
                     <div className='col-md-2'></div>
                     <div className='col-md-8 text-left p-4'>
                     <div className='form-group'>
                   <label className={styles.label}>Address</label>
                   <input className='form-control' type="text" name='address_one' id="address_one"
                    placeholder='Enter Address'
                    onChange={handleChange}
                   onBlur={handleBlur}
                   value={values.address_one}
                    />
                    <small  className='text-danger'>{errors.address_one && touched.address_one && errors.address_one}</small>
                    </div>
                    <div className='form-group mt-3'>
                   <label className={styles.label}>Address (cont.)</label>
                   <input className='form-control' type="text" name='address_two'
                    id="address_two" placeholder='Address 2'
                    onChange={handleChange}
                   onBlur={handleBlur}
                   value={values.address_two}
                    />
                 
               </div>
               <div className='form-group mt-3'>
                   <label className={styles.label}>Country</label>
                   <input className='form-control' type="text" name='country' id="country"
                    placeholder='Enter Country'
                    onChange={handleChange}
                   onBlur={handleBlur}
                   value={values.country}
                    />
                  <small  className='text-danger'>{errors.country && touched.country && errors.country}</small>
               </div>
               <div className='form-group mt-3'>
                   <label className={styles.label}>City</label>
                   <input className='form-control' name='city' type="text" id="city" placeholder='City'
                   onChange={handleChange}
                   onBlur={handleBlur}
                   value={values.city}
                   />
                  <small  className='text-danger'>{errors.city && touched.city && errors.city}</small>
               </div>
               <div className='form-group mt-3'>
                   <label className={styles.label}>State</label>
                   <input className='form-control' name='state' type="text" id="state" placeholder='State'
                   onChange={handleChange}
                   onBlur={handleBlur}
                   value={values.state}
                   />
                  <small  className='text-danger'>{errors.state && touched.state && errors.state}</small>
               </div>
               <div className='form-group mt-3'>
                   <label className={styles.label}>Zip/Postal code</label>
                   <input className='form-control' type="text" name='postal' id="postal"
                    placeholder='Enter Postalcode'
                    onChange={handleChange}
                   onBlur={handleBlur}
                   value={values.postal}
                    />
                  <small  className='text-danger'>{errors.postal && touched.postal && errors.postal}</small>
               </div>
               
                     </div>
                     <div className='col-md-2'></div>
                 </div>
              </div>
              <div className="container-fluid !direction !spacing">
       <div className="row mb-4">
       <div className='col-md-4'></div>
           <div className='col-md-4'>
       
                   <div className='form-group mt-3 text-center'>
                  
                  <button  className={`form-control btn `+styles.createbtn} disabled={isSubmitting} type="submit">Save</button>
                    
                   
               </div>
           
       </div>
       <div className='col-md-4'></div>
       </div>
   </div>
        </form>
      )}
    </Formik>

   

    </>}

           </div>
           <div className='col-md-1'></div>
       </div>
   </div>

   
   </> );

       }
 
export default SellerRegistration;