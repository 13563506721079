import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import "@fontsource/roboto";
import "@fontsource/nunito-sans";
import "@fontsource/dm-sans";
import styles from './footer.module.css';
const Footer = () => {
    return ( 
        <>
        <div className={`container-fluid mt-5 `+styles.footer}>
            <div className="row">
                <div className={`col-md-6 `+styles.footerborder}>
                    <h1><img src='./images/logo.png' /></h1>
                    <p>Ready2Resale All rights reserved Dubai.</p>
                </div>

                <div className="col-md-6">
                <div className={styles.dashboardmenu}>
                 <ul>
                  <li>How it works</li>
                  <li>About</li>
                  <li>FAQ</li>
                  <li>Contact</li>
                  <li>Login</li>
                  <li> <button className={`btn `+styles.signup}>Signup</button> </li>     
                </ul>
                <div>
                
                </div>
                
                </div>
                <div className={styles.copyright}>
                 <p className={`mt-5 `} >&copy; 2022  <span>Privacy — Terms</span>   </p>
                 <p></p>
                 </div>
                
                </div>

            </div>
        </div>
        </>
     );
}
 
export default Footer;
<></>