import React,{useState} from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import "@fontsource/roboto";
import "@fontsource/nunito-sans";
import { Formik } from 'formik';
import styles from './signup.module.css';
import { useNavigate } from 'react-router-dom';
import { postData } from '../../services/services';
import Swal from 'sweetalert2';
const Sellersignup = () => {
    const navigate = useNavigate();
    const [errormsg , setErrormsg] = useState('')
    function loginredirect(){
      
        navigate('/sellerlogin')
    }
    return ( <>
    <div className="container-fluid !direction !spacing">
    <div className="row" >
        <div className={styles.topbar}>
            <p className={styles.toptext}> <img src="./images/logo.png"  /></p>
        </div>
    </div>
    </div>
    
    <div className="container-fluid !direction !spacing">
        <div className="row">
            <div className='col-md-4'></div>
            <div className='col-md-4 ' >
                <div className='text-center'>
                <h1 className={styles.signupheading}>Create seller account</h1>
                <p className={styles.signuptext}>Sign up to start your business</p>

               
                </div>
              
               <div className={styles.form}>
               <Formik
       initialValues={{ email: '', password: '',name: 'NewSeller' }}
       validate={values => {
         const errors = {};
         if (!values.email) {
           errors.email = 'Required';
         } else if (
           !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)
         ) {
           errors.email = 'Invalid email address';
         }
         
         
         if (!values.password) {
            errors.password="Required" 
         }
         return errors;
       }}
       onSubmit={(values, { setSubmitting }) => {

        var  url ='seller/register'
        var response =  postData(url,values);
        response.then(response=>{
            
            if (response.data.user.role=='seller') {

                Swal.fire({
                  position: 'center',
                  icon: 'success',
                  title: 'Seller Account Created Successfully',
                  showConfirmButton: false,
                  timer: 1500
                }).then(()=>{
                  navigate('/sellerlogin')
                })
                 
               
                
              }
        })
        .catch(error => {
         var emailerror =  error.response.data.errors.email
        
          if (emailerror == undefined) {
            setErrormsg(error.response.data.errors.password)
          } else if (error.response.data.errors.password == undefined) {
            setErrormsg(error.response.data.errors.email)
          }else{
            setErrormsg(error.response.data.errors.email+ '-' + error.response.data.errors.password)
          }
             
         
          
       })

           setSubmitting(false);
        
       }}
     >
       {({
         values,
         errors,
         touched,
         handleChange,
         handleBlur,
         handleSubmit,
         isSubmitting,
         /* and other goodies */
       }) => (
         <form onSubmit={handleSubmit}>
             <div className='form-group'>
             <label className={styles.label}>Email</label>
             <input
             type="email"
             name="email"
             className='form-control'  id="email" placeholder='mail@abc.com'
             onChange={handleChange}
             onBlur={handleBlur}
             value={values.email}
           />
           <small  className='text-danger'>{errors.email && touched.email && errors.email}</small>
             </div>
         <div className='form-group mt-3'>
         <label className={styles.label}>Password</label>
       
           <input
             type="password"
             name="password"
             className='form-control'  id="password" placeholder='***********'
             onChange={handleChange}
             onBlur={handleBlur}
             value={values.password}
           />
           <small className='text-danger'>{errors.password && touched.password && errors.password}</small>
           </div>
           <input type="hidden" name='name' value={values.name} onChange={handleChange}></input>
           <div className='form-group mt-3 text-center'>
                   
                   <button  className={`form-control btn `+styles.signupbtn} disabled={isSubmitting} >Create</button>
                   <small className='text-danger'>{errormsg}</small>
                   <p className={`mt-4 `+styles.alreadyacc} onClick={loginredirect}>Already have an account?</p>
                </div>
         </form>
       )}
     </Formik>
               </div>
                
            </div>
            <div className='col-md-4'></div>
        </div>
    </div>
    
  
    </> );
}
 
export default Sellersignup;