import axios from "axios";

export function postData(url,data) {
    
  var config = {
    method: 'post',
    url: 'https://r2r.speedy-wiki-listing.com/admin_new/api/'+url,data,
    headers: { 
      'Accept': 'application/json', 
      'Authorization': 'Bearer '+localStorage.getItem('access_token')
    }
  };
  const res=axios(config)
  return res
   

  }

  export function getData(url,data){
   
    var config = {
      method: 'get',
      url: 'https://r2r.speedy-wiki-listing.com/admin_new/api/'+url,data,
      headers: { 
        'Accept': 'application/json', 
        'Authorization': 'Bearer '+localStorage.getItem('access_token')
      }
    };
    const res=axios(config)
    return res
     
    
  }